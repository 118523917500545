import React, { useState, useEffect } from 'react';
import { navigate } from 'gatsby';
import styled from 'styled-components';
import moment from 'moment';
import 'moment/locale/vi';

import * as API from '../../apis';
import Spinner from '../Spinner';
import MutedText from '../MutedText';
import Button from '../Button';
import Actions from './Actions';

const Subscription = styled.div`
  padding: 1rem;
  border: 1px solid
    ${({ expired, expireSoon }) => {
      if (expired) {
        return '#f5222d';
      }
      if (expireSoon) {
        return '#faad14';
      }
      return '#52c41a';
    }};
  border-radius: 4px;

  & + & {
    margin-top: 1rem;
  }
`;

const Name = styled.div`
  margin-bottom: 0.5rem;
  font-size: 1.125rem;
  font-weight: 600;
`;

const Date = styled.div`
  span {
    font-weight: 500;
  }
`;

function isExpiredInNext(date, days) {
  return moment()
    .add(days, 'days')
    .isAfter(moment(date));
}

function isExpired(date) {
  return moment().isAfter(moment(date));
}

function SubscriptionInfo() {
  const [subscriptions, setSubscriptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const purchaseButton = (
    <Actions>
      <Button onClick={() => navigate('/purchase-subscription')}>Mua thêm gói</Button>
    </Actions>
  );

  useEffect(() => {
    const fetch = async () => {
      try {
        setLoading(true);
        const response = await API.getMySubscriptions();
        setLoading(false);
        setSubscriptions(response);
      } catch (error) {
        setLoading(false);
        console.error(error);
      }
    };

    fetch();
  }, []);

  if (loading) {
    return <Spinner />;
  }

  if (!subscriptions || !subscriptions.length) {
    return (
      <>
        <MutedText>Bạn chưa mua gói nào</MutedText>
        {purchaseButton}
      </>
    );
  }

  return (
    <>
      {subscriptions.map(subscription => {
        if (!subscription || !subscription.expirationDate || !subscription.type) {
          return <></>;
        }

        const expired = isExpired(subscription.expirationDate);
        const expireSoon = isExpiredInNext(subscription.expirationDate, 7);

        return (
          <Subscription key={subscription.type.code} expired={expired} expireSoon={expireSoon}>
            <Name>{`Gói ${subscription.type.name}`}</Name>
            <Date>
              <span>Ngày hết hạn: </span>
              {moment(subscription.expirationDate)
                .locale('vi')
                .format('LLL')}
            </Date>
          </Subscription>
        );
      })}
      {purchaseButton}
    </>
  );
}

export default SubscriptionInfo;

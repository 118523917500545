import React from 'react';
import { Router } from '@reach/router';

import Layout from '../components/Layout';
import PrivateRoute from '../components/PrivateRoute';
import Profile from '../components/Profile';
import MyVideos from '../components/MyVideos';

function User() {
  return (
    <Layout>
      <Router>
        <PrivateRoute path="/profile" component={Profile} />
        <PrivateRoute path="/my-videos" component={MyVideos} />
      </Router>
    </Layout>
  );
}
export default User;

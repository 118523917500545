import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';

import * as API from '../../apis';
import { slugify } from '../../utils/slug';
import Video from '../Video';
import VideoPlaceholder from '../Placeholder/Video';
import MutedText from '../MutedText';
import Spinner from '../Spinner';
import { LABEL } from '../../constants/ga';
import { countViews } from '../../utils/misc';
import NotFoundPic from '../../images/not-found.jpg';

const Title = styled.h1`
  margin: 0 1rem 1rem 0;
  font-size: 1.25rem;
  font-weight: 500;
  text-transform: capitalize;
  color: #10abe2;
`;

const VideoList = styled.div`
  display: grid;
  grid-gap: 1.875rem;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  margin-bottom: 1.875rem;
`;

const LoadMoreButton = styled.div`
  color: #10abe2;
  cursor: pointer;
`;

const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

function MyVideos() {
  const [videos, setVideos] = useState([]);
  const [loading, setLoading] = useState({});
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(false);

  const fetchVideos = useCallback(
    async (limit = 20) => {
      try {
        setLoading(true);
        const response = await API.getFavoriteVideos({
          page,
          limit,
        });
        setLoading(false);
        setVideos([...videos, ...response.videos]);
        setHasMore(response.hasNextPages);
      } catch (error) {
        setLoading(false);
        console.error(error);
      }
    },
    [page]
  );

  useEffect(() => {
    fetchVideos();
  }, [fetchVideos]);

  return (
    <>
      <Title>Video của tôi</Title>

      {!loading && !(videos && videos.length) && (
        <>
          <MutedText>Bạn chưa có video được thêm vào mục &ldquo;Video của tôi&ldquo;.</MutedText>
          <br />
          <img src={NotFoundPic} alt="Không có video của tôi." />
        </>
      )}

      <VideoList>
        {loading && page === 1 && (
          <>
            <VideoPlaceholder />
            <VideoPlaceholder />
            <VideoPlaceholder />
            <VideoPlaceholder />
          </>
        )}
        {videos &&
          videos.map(video => (
            <Video
              key={video.code}
              title={video.name}
              subtitle={video.subject && video.subject.name}
              thumbnail={video.thumbnail}
              link={`videos/${slugify(video.name)}`}
              duration={video.duration}
              viewCount={countViews(video.views, video.demoViews)}
              publishDate={video.publishDate}
              gaLabel={LABEL.MY_VIDEO_PAGE}
            />
          ))}
      </VideoList>

      <Footer>
        {hasMore && !loading && (
          <LoadMoreButton onClick={() => setPage(page + 1)}>Xem thêm</LoadMoreButton>
        )}

        {loading && hasMore && <Spinner />}
      </Footer>
    </>
  );
}

export default MyVideos;

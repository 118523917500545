import React, { useState, useEffect } from 'react';
import ReactGA from 'react-ga';

import * as API from '../../apis';
import { ENTER } from '../../constants/keys';
import { ACTION, CATEGORY } from '../../constants/ga';
import Input from '../Input';
import Button from '../Button';
import Spinner from '../Spinner';
import Message from '../Message';
import Field from './Field';
import Actions from './Actions';

function UpdateProfile() {
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const invalid = !firstName && !lastName;

  const handleUpdateProfile = async () => {
    if (invalid || submitting) {
      return;
    }

    try {
      setSubmitting(true);
      setErrorMessage('');
      setSuccessMessage('');
      await API.updateUserProfile({ firstName, lastName });
      setSubmitting(false);
      setSuccessMessage('Lưu thông tin thành công');
    } catch (error) {
      setSubmitting(false);
      if (error.response && error.response.data && error.response.data.message) {
        setErrorMessage(error.response.data.message);
      } else {
        setErrorMessage('Có lỗi xảy ra. Vui lòng thử lại sau.');
      }
      console.error(error);
    }

    ReactGA.event({
      category: CATEGORY.USER_MANAGEMENT,
      action: ACTION.UPDATE_PROFILE,
    });
  };

  useEffect(() => {
    const fetch = async () => {
      try {
        setLoading(true);
        const response = await API.getUserProfile();
        setEmail(response.email);
        setFirstName(response.name && response.name.first);
        setLastName(response.name && response.name.last);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error(error);
      }
    };

    fetch();
  }, []);

  if (loading) {
    return <Spinner />;
  }

  return (
    <>
      <Field>
        <Input label="Email" placeholder="Địa chỉ email của bạn" value={email} disabled />
      </Field>
      <Field>
        <Input
          label="Họ"
          placeholder="Nhập họ và tên đệm"
          value={lastName}
          hasError={!!errorMessage}
          onChange={event => setLastName(event.target.value)}
          onKeyDown={event => {
            if (event.keyCode === ENTER) {
              handleUpdateProfile();
            }
          }}
        />
      </Field>
      <Field>
        <Input
          label="Tên"
          placeholder="Nhập tên"
          value={firstName}
          hasError={!!errorMessage}
          onChange={event => setFirstName(event.target.value)}
          onKeyDown={event => {
            if (event.keyCode === ENTER) {
              handleUpdateProfile();
            }
          }}
        />
      </Field>
      <Actions>
        <Button loading={submitting} disabled={invalid || submitting} onClick={handleUpdateProfile}>
          Lưu
        </Button>
      </Actions>
      {errorMessage && <Message error>{errorMessage}</Message>}
      {successMessage && <Message>{successMessage}</Message>}
    </>
  );
}

export default UpdateProfile;
